@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,400);
.error-header {
    font-size: 24px; 
    padding: 30px 0px;
    text-transform: uppercase;
    font-weight: 600;    
}
.leaflet-container {
    height: 250px;
    width: 100%;
}

.home-data-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
}

.home-data-buttons-left {
    padding-right: 20px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { 
    .home-welcome-image {
        width: 30% 
    }
    .home-data-buttons {
        width: 20%;
    }    
}
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) {
    .home-welcome-image {
        width: 55% 
    }
    .home-data-buttons {
        width: 20%;
    }       
 }

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { 
    .home-welcome-image {
        width: 55% 
    }
    .home-data-buttons {
        width: 30%;
    }     
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .home-welcome-image {
        width: 55% 
    }
 }




 .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin-bottom: 40px;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: .625rem;
    height: 3.5rem;
}
.card-header, .card-title {
    text-transform: capitalize;
    color: rgba(31,10,6,0.6);
}
.card-header {
    font-weight: bold;
    font-size: .9rem;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}
.timeline-title
{
    font-size: 18px;
    color: #3498db;
    font-weight: 600;
}

.drop-dispatched {
    color: #5B8DBB;
    background-color: white;
    font-size: 24px !important;
}

.drop-done {
    color: #2ABA66;
    background-color: white;
    font-size: 24px !important;
}

.drop-skipped {
    color: #DA4453;
    background-color: white;
    font-size: 24px !important;
}

.drop-busy {
    color: #5B8DBB;
    background-color: white;
    font-size: 24px !important;
}

.drop-header {
    color: #6c6c6c;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.drop-header-original {
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.16);
    margin-bottom: 3px;
    padding: 2px 10px;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
    font-weight: 700;
    color: #6c6c6c;
}

.drop-container {
    box-shadow: 0px 1px 7px 1px rgba(69, 65, 78, 0.16);
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
}

.drop-body {
    background-color: #ffffff;
    border-radius: 7px;
    color: #6c6c6c;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.drop-customer-title {
    font-size: 18px;
}

.drop-buttons {
    display: flex;
    border-radius: 8px;
    padding: 10px;
    flex-wrap: wrap;
}

.drop-buttons > a > button {
    width: 110px;
}

.drop-buttons > button {
    width: 110px;
}

.drop-buttons > button {
    font-weight: 600; 
    margin: 0px 4px 4px 0px;
}

    .drop-buttons > a {
        font-weight: 600;
        margin: 0px 4px 4px 0px;
    }

.drop-header-overtime {
    color: #e74c3c;
}

.timeline-body {
    padding-top: 10px;
    box-shadow: none!important;    
    padding-top: 0px;
    margin-top: 10px;
    position: relative;
}

.timeline-deco {
    background-color: rgba(0,0,0,0.08);
    position: absolute;
    height: 100%;
    left: 12px;
    width: 1px;
}

.timeline-item .timeline-icon {
    position: absolute;
    width: 24px;
    height: 45px;
    left: 0px;
    line-height: 45px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
}

.timeline-item-content, .timeline-item-content-full {
    background-color: #FFF;
    margin: 0px 0px 0px 35px;
    padding: 10px 5px 15px 10px;
}
.finishedImage {
    padding: 30px;
}

#timelineActionsWrapper {
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    justify-content: space-between;
}

#timelineActionsWrapper > div {
    -webkit-box-flex: 1;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { 
    .finishedImage {
        max-width: 75%;   
    }
}
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) { 
    .finishedImage {
        max-width: 75%;
    }    
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { 
    .finishedImage {
        max-width: 100%;
    }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
    .finishedImage {
        max-width: 65%;
    }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/  
@media (min-width: 481px) and (max-width: 767px) {
    .finishedImage {
        max-width: 100%;
    }
 }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .finishedImage {
        max-width: 100%;
    }
}
/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .arrive-buttons {
        display: block !important;
    }  
    .arrive-buttons-left {
        padding: 5px 0px !important;
    }    
    .arrive-buttons-right {
        padding: 5px 0px !important;
    }    
}

.arrive-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.arrive-buttons-left {
    padding: 5px;
}

.arrive-buttons-right {
    padding: 5px 0px 5px 5px; 
}

.MuiCardActions-root {
    border-top: 1px solid #eaeaea;
    padding: 5px 5px !important;
    margin: 0px 12px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.22) !important;
}

.form-label {
    color: rgba(0, 0, 0, 0.54) !important;
}
/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .job-buttons {
        display: block !important;
    }  
    .job-buttons-left {
        padding: 5px 0px !important;
    }
    
    .job-buttons-right {
        padding: 5px 0px !important;
    }    
}

.job-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.job-buttons-left {
    padding: 5px;
}

.job-buttons-right {
    padding: 5px 0px 5px 5px;
}

.MuiCardActions-root {
    border-top: 1px solid #eaeaea;
    padding: 5px 5px !important;
    margin: 0px 12px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.22) !important;
}

.form-label {
    color: rgba(0, 0, 0, 0.54) !important;
}

.jobview-inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .skip-buttons {
        display: block !important;
    }  
    .skip-buttons-left {
        padding: 5px 0px !important;
    }    
    .skip-buttons-right {
        padding: 5px 0px !important;
    }    
}

.skip-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.skip-buttons-left {
    padding: 5px;
}

.skip-buttons-right {
    padding: 5px 0px 5px 5px;
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
}
.info-general-item {
    display: flex;
    padding-bottom: 20px;
}

.info-general-icon {
    padding-right: 20px; 
    display: flex;
}

.info-general-icon > div {
    align-self: center;
}
/* .frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.3);
  background: #FDA231;
  background: -webkit-gradient(linear, left bottom, right top, from(#FDA231), to(#FDCA31));
  background: linear-gradient(to top right, #FDA231 0%, #FDCA31 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FDA231', endColorstr='#FDCA31',GradientType=1 );
  color: #497081;
  font-family: 'Open Sans', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* .list {
  position: absolute;
  width: 240px;
  height: 280px;
  top: 60px;
  left: 80px;
  background: #FFFFFF;
  box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
} */
/* .list .head {
  padding: 20px 0;
  margin: 0 30px;
  border-bottom: 1px solid #D4DCE0;
}
.list .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.list .subtitle {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
} */
.list ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.list ul li {
  position: relative;
  display: block;
  margin: 24px 0;
  height: 14px;
}
.list ul .text {
  float: left;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  width: 90%;
}
.list ul .button {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  float: right;
  width: 17px;
  height: 17px;
  border: 1px solid #497081;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s ease-out .5s;
}
.list ul .checkmark {
  position: absolute;
  top: 2px;
  right: 0;
  stroke: #C8D4D9;
  fill: none;
  stroke-width: 1.5;
  stroke-dasharray: 30 30;
  stroke-dashoffset: 30;
  transition: all .5s ease-out;
  transform: scale(1.3);
}
.list ul input {
  display: none;
}
.list ul input:checked ~ .text {
  color: #C8D4D9;
}
.list ul input:checked ~ .button {
  transition: all .4s ease-in;
  transform: scale(1.5);
  opacity: 0;
}
.list ul input:checked ~ .checkmark {
  stroke-dashoffset: 0;
  transition: all .5s ease-out .4s;
  transform: scale(1.3);
}



.kt-widget4 .kt-widget4__item:first-child {
    padding-top: 0;
}
.kt-widget4 .kt-widget4__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dashed #ebedf2;
}
.kt-widget4 .kt-widget4__item .kt-widget4__pic {
    padding-right: 1rem;
}
.kt-widget4 .kt-widget4__item .kt-widget4__pic img {
    width: 2.5rem;
    border-radius: 4px;
    vertical-align: middle;
    border-style: none;
}
.kt-widget4 .kt-widget4__item .kt-widget4__info {
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    flex-grow: 1;
}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__username {
    font-weight: 700;
    font-size: 1rem;
    color: #595d6e;
    transition: color .3s ease;
}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__text {
    font-size: 1rem;
    margin: 0;
    color: #74788d;
}
.btn.btn-label-brand {
    background-color: rgba(93,120,255,.1);
    color: #5d78ff;
    cursor: text!important;
}
.settings-container {
    display: flex;
    justify-content: space-between;
    color: #6c6c6c;
}
    .settings-container:first-child {        
        padding: 5px 0px 0px 0px;        
    }

    .settings-container > svg {
        margin-left: auto;
        display: block;
        line-height: 55px;
        position: absolute;
        right: 0px;
        top: 20px;
    }

    .settings-container:first-child > i {        
        top: 0px;
    }    

.flex-column {
    flex-direction: column;
    position: relative;
}

.settings-selected-value {
    font-size: 0.8rem;
    color: #4A89DC;
    font-weight: 400;
    line-height: 5px;
}

.MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.MuiFormGroup-row {
    width: 100% !important;
}

.MuiSwitch-root {
    padding-right: 0px !important;
    width: 49px !important;
}
.radio-container {    
    color: #6c6c6c;
}
* {
    font-family: 'Assistant', sans-serif;  
    font-size: 16px;      
    letter-spacing: 0.03em;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Assistant', sans-serif;
    letter-spacing: 0.05em;
}

body {
    background-color: #fff !important;
}

li { -webkit-tap-highlight-color: rgba(0,0,0,0); }
a { text-decoration: none;  -webkit-tap-highlight-color: rgba(0,0,0,0); }
a:hover {text-decoration: none;}

.center { text-align: center; }
.padding-bottom-10 { padding-bottom: 10px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-50 { padding-bottom: 50px; }

.gradient {
    height: 20px;
    position: relative;
    width: 100%;
    background: radial-gradient(ellipse farthest-side at top center, rgba(0,0,0,0.08), transparent);
  }
  .gradient:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;    
    left: 0;
    height: 3px;
    background: linear-gradient(to left, transparent, rgba(0,0,0,0.02), rgba(0,0,0,0.02), transparent);
  }

.divider {
    margin: 0px 12px;
    border: 0;
    border-top: 1px solid #8c8c8c42;
    border-bottom: 1px solid #fff;
}

.full-divider {
    border: 0;
    border-top: 1px solid #8c8c8c42;
    border-bottom: 1px solid #fff;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { }
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) { }

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { }

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/  
@media (min-width: 481px) and (max-width: 767px) {
    * {
        font-size: 14px;      
    }
 }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    * {
        font-size: 14px;      
    }    
}

/* general overrides for material ui */
/* .MuiInputBase-input {
    color: #6c6c6c !important;
}

.MuiIcon-root {
    overflow: initial !important;
} */

.MuiTypography-body1 {
    font-family: 'Assistant', sans-serif !important;  
}

.MuiIcon-root {
    overflow: initial !important;
}

.global-container {
    padding: 16px 24px;
}

.MuiBox-root {
    padding: 0px !important;
}

.blue-icon {
    --fa-primary-color: #3498db; 
    --fa-secondary-color: #95a5a6;
}

.default-link,
.default-link:active,
.default-link:hover,
.default-link:visited,
.default-link:focus {
    text-decoration: none !important;
    color: inherit !important;
    background-color: inherit !important;
    border: none !important;
    -webkit-tap-highlight-color: transparent !important;    
    outline-color: transparent;
    padding: 0 3px;
}

.button-text-with-icon {
    font-size: 13px;
    padding: 0px 0px 0px 5px;
    font-weight: 700;
}

/*New screens*/
.img-fluid {
    max-width: 100%;
    height: auto;
}
