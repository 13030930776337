* {
    font-family: 'Assistant', sans-serif;  
    font-size: 16px;      
    letter-spacing: 0.03em;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Assistant', sans-serif;
    letter-spacing: 0.05em;
}

body {
    background-color: #fff !important;
}

li { -webkit-tap-highlight-color: rgba(0,0,0,0); }
a { text-decoration: none;  -webkit-tap-highlight-color: rgba(0,0,0,0); }
a:hover {text-decoration: none;}

.center { text-align: center; }
.padding-bottom-10 { padding-bottom: 10px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-50 { padding-bottom: 50px; }

.gradient {
    height: 20px;
    position: relative;
    width: 100%;
    background: radial-gradient(ellipse farthest-side at top center, rgba(0,0,0,0.08), transparent);
  }
  .gradient:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;    
    left: 0;
    height: 3px;
    background: linear-gradient(to left, transparent, rgba(0,0,0,0.02), rgba(0,0,0,0.02), transparent);
  }

.divider {
    margin: 0px 12px;
    border: 0;
    border-top: 1px solid #8c8c8c42;
    border-bottom: 1px solid #fff;
}

.full-divider {
    border: 0;
    border-top: 1px solid #8c8c8c42;
    border-bottom: 1px solid #fff;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { }
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) { }

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { }

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/  
@media (min-width: 481px) and (max-width: 767px) {
    * {
        font-size: 14px;      
    }
 }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    * {
        font-size: 14px;      
    }    
}

/* general overrides for material ui */
/* .MuiInputBase-input {
    color: #6c6c6c !important;
}

.MuiIcon-root {
    overflow: initial !important;
} */

.MuiTypography-body1 {
    font-family: 'Assistant', sans-serif !important;  
}

.MuiIcon-root {
    overflow: initial !important;
}

.global-container {
    padding: 16px 24px;
}

.MuiBox-root {
    padding: 0px !important;
}

.blue-icon {
    --fa-primary-color: #3498db; 
    --fa-secondary-color: #95a5a6;
}

.default-link,
.default-link:active,
.default-link:hover,
.default-link:visited,
.default-link:focus {
    text-decoration: none !important;
    color: inherit !important;
    background-color: inherit !important;
    border: none !important;
    -webkit-tap-highlight-color: transparent !important;    
    outline-color: transparent;
    padding: 0 3px;
}

.button-text-with-icon {
    font-size: 13px;
    padding: 0px 0px 0px 5px;
    font-weight: 700;
}

/*New screens*/
.img-fluid {
    max-width: 100%;
    height: auto;
}