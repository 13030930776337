/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .arrive-buttons {
        display: block !important;
    }  
    .arrive-buttons-left {
        padding: 5px 0px !important;
    }    
    .arrive-buttons-right {
        padding: 5px 0px !important;
    }    
}

.arrive-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.arrive-buttons-left {
    padding: 5px;
}

.arrive-buttons-right {
    padding: 5px 0px 5px 5px; 
}

.MuiCardActions-root {
    border-top: 1px solid #eaeaea;
    padding: 5px 5px !important;
    margin: 0px 12px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.22) !important;
}

.form-label {
    color: rgba(0, 0, 0, 0.54) !important;
}