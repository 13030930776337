/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .job-buttons {
        display: block !important;
    }  
    .job-buttons-left {
        padding: 5px 0px !important;
    }
    
    .job-buttons-right {
        padding: 5px 0px !important;
    }    
}

.job-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.job-buttons-left {
    padding: 5px;
}

.job-buttons-right {
    padding: 5px 0px 5px 5px;
}

.MuiCardActions-root {
    border-top: 1px solid #eaeaea;
    padding: 5px 5px !important;
    margin: 0px 12px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.22) !important;
}

.form-label {
    color: rgba(0, 0, 0, 0.54) !important;
}

.jobview-inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}