/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .skip-buttons {
        display: block !important;
    }  
    .skip-buttons-left {
        padding: 5px 0px !important;
    }    
    .skip-buttons-right {
        padding: 5px 0px !important;
    }    
}

.skip-buttons {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
}

.skip-buttons-left {
    padding: 5px;
}

.skip-buttons-right {
    padding: 5px 0px 5px 5px;
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
}