.settings-container {
    display: flex;
    justify-content: space-between;
    color: #6c6c6c;
}
    .settings-container:first-child {        
        padding: 5px 0px 0px 0px;        
    }

    .settings-container > svg {
        margin-left: auto;
        display: block;
        line-height: 55px;
        position: absolute;
        right: 0px;
        top: 20px;
    }

    .settings-container:first-child > i {        
        top: 0px;
    }    

.flex-column {
    flex-direction: column;
    position: relative;
}

.settings-selected-value {
    font-size: 0.8rem;
    color: #4A89DC;
    font-weight: 400;
    line-height: 5px;
}

.MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.MuiFormGroup-row {
    width: 100% !important;
}

.MuiSwitch-root {
    padding-right: 0px !important;
    width: 49px !important;
}