.leaflet-container {
    height: 250px;
    width: 100%;
}

.home-data-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
}

.home-data-buttons-left {
    padding-right: 20px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { 
    .home-welcome-image {
        width: 30% 
    }
    .home-data-buttons {
        width: 20%;
    }    
}
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) {
    .home-welcome-image {
        width: 55% 
    }
    .home-data-buttons {
        width: 20%;
    }       
 }

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { 
    .home-welcome-image {
        width: 55% 
    }
    .home-data-buttons {
        width: 30%;
    }     
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .home-welcome-image {
        width: 55% 
    }
 }




 .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin-bottom: 40px;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: .625rem;
    height: 3.5rem;
}
.card-header, .card-title {
    text-transform: capitalize;
    color: rgba(31,10,6,0.6);
}
.card-header {
    font-weight: bold;
    font-size: .9rem;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}