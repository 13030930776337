.timeline-title
{
    font-size: 18px;
    color: #3498db;
    font-weight: 600;
}

.drop-dispatched {
    color: #5B8DBB;
    background-color: white;
    font-size: 24px !important;
}

.drop-done {
    color: #2ABA66;
    background-color: white;
    font-size: 24px !important;
}

.drop-skipped {
    color: #DA4453;
    background-color: white;
    font-size: 24px !important;
}

.drop-busy {
    color: #5B8DBB;
    background-color: white;
    font-size: 24px !important;
}

.drop-header {
    color: #6c6c6c;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.drop-header-original {
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.16);
    margin-bottom: 3px;
    padding: 2px 10px;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
    font-weight: 700;
    color: #6c6c6c;
}

.drop-container {
    box-shadow: 0px 1px 7px 1px rgba(69, 65, 78, 0.16);
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
}

.drop-body {
    background-color: #ffffff;
    border-radius: 7px;
    color: #6c6c6c;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.drop-customer-title {
    font-size: 18px;
}

.drop-buttons {
    display: flex;
    border-radius: 8px;
    padding: 10px;
    flex-wrap: wrap;
}

.drop-buttons > a > button {
    width: 110px;
}

.drop-buttons > button {
    width: 110px;
}

.drop-buttons > button {
    font-weight: 600; 
    margin: 0px 4px 4px 0px;
}

    .drop-buttons > a {
        font-weight: 600;
        margin: 0px 4px 4px 0px;
    }

.drop-header-overtime {
    color: #e74c3c;
}

.timeline-body {
    padding-top: 10px;
    box-shadow: none!important;    
    padding-top: 0px;
    margin-top: 10px;
    position: relative;
}

.timeline-deco {
    background-color: rgba(0,0,0,0.08);
    position: absolute;
    height: 100%;
    left: 12px;
    width: 1px;
}

.timeline-item .timeline-icon {
    position: absolute;
    width: 24px;
    height: 45px;
    left: 0px;
    line-height: 45px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
}

.timeline-item-content, .timeline-item-content-full {
    background-color: #FFF;
    margin: 0px 0px 0px 35px;
    padding: 10px 5px 15px 10px;
}
.finishedImage {
    padding: 30px;
}

#timelineActionsWrapper {
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    justify-content: space-between;
}

#timelineActionsWrapper > div {
    -webkit-box-flex: 1;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) { 
    .finishedImage {
        max-width: 75%;   
    }
}
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/  
@media (min-width: 1025px) and (max-width: 1280px) { 
    .finishedImage {
        max-width: 75%;
    }    
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) { 
    .finishedImage {
        max-width: 100%;
    }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
    .finishedImage {
        max-width: 65%;
    }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/  
@media (min-width: 481px) and (max-width: 767px) {
    .finishedImage {
        max-width: 100%;
    }
 }

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/  
@media (min-width: 320px) and (max-width: 480px) { 
    .finishedImage {
        max-width: 100%;
    }
}